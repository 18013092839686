import styled from '@emotion/styled';
import css from '@emotion/css';
import { COUNTRY } from '../configs/env';

export type PropsType = {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  borderHeight?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundSize?: string;
  border?: string;
  fontSize?: string;
  fontFamily?: string;
  textTransform?: string;
  opacity?: string;
  height?: string;
  width?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  textAlign?: string;
  fontWeight?: string;
  maxHeight?: string;
  maxWidth?: string;
  overflow?: string;
  objectFit?: string;
  flexDirection?: string;
  position?: string;
  borderRadius?: string;
  lineHeight?: string;
  borderBottom?: string;
  minHeight?: string;
  borderRight?: string;
  left?: string;
  showPopup?: boolean;
  closePopup?: boolean;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
};

export const Link = styled.a`
  transition: ease 0.3s;
  color: #ffffff;
  text-decoration: none;
  :hover {
    color: #f47e20;
  }
`;

export const RegularLink = styled.a`
  color: inherit;
  transition: ease 0.3s;
  text-decoration: none;
  font-size: 14px;
  padding: 10px;

  @media screen and (max-width: 370px) {
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  @media screen and (min-width: 2200px) {
    font-size: 20px;
  }
`;

export const RegularButton = styled.button`
  background-color: transparent;
  border: none;

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 14px;
  }
`;

export const VozziButton = styled.button`
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: PropsType) => props.justifyContent || 'flex-start'};
  padding: ${(props: PropsType) => props.padding || '15px 20px 16px'};
  gap: 8px;

  width: ${(props: PropsType) => props.width || 'auto'};
  height: ${(props: PropsType) => props.height || '60px'};

  background: #f47e20;
  border: none;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: ${(props: PropsType) => props.textAlign || 'left'};
  border-radius: ${(props: PropsType) => props.borderRadius || '22px'};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  margin-top: ${(props: PropsType) => props.marginTop};
  margin-bottom: ${(props: PropsType) => props.marginBottom};
  cursor: pointer;
  transition: ease 0.3s;

  :hover {
    transform: translateY(-1px);
    box-shadow: 0px 14px 20px -10px rgba(244, 126, 32, 1);
  }

  @media screen and (max-width: 370px) {
    margin: 20px auto !important;
  }

  @media screen and (max-width: 768px) {
    margin: 30px auto;
    height: 50px;
    font-size: 16px;
    border-radius: 15px;
    &.mobile-left {
      margin: 0px 0px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1400px) {
    height: 60px;
    font-size: 14px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 24px;
    height: 70px;
    & img {
      width: 40px;
    }
  }

  @media screen and (min-width: 2200px) {
    font-size: 32px;
    height: 100px;
    padding: 25px 30px;

    & img {
      width: 60px;
    }
  }
`;

export const SimpleButton = styled.button`
  width: 100%;
  height: 60px;
  padding: ${(props: PropsType) => props.padding || '0'};
  margin: ${(props: PropsType) => props.margin || '0'};
  border: ${(props: PropsType) => props.border || '1px solid white'};
  color: ${(props: PropsType) => props.color || 'white'};
  font-style: normal;
  font-weight: ${(props: PropsType) => props.fontWeight || 500};
  font-size: 18px;
  border-top-left-radius: ${(props: PropsType) => props.borderTopLeftRadius || '0'};
  border-bottom-left-radius: ${(props: PropsType) => props.borderBottomLeftRadius || '0'};
  border-top-right-radius: ${(props: PropsType) => props.borderTopRightRadius || '0'};
  border-bottom-right-radius: ${(props: PropsType) => props.borderBottomRightRadius || '0'};
  border-radius: ${(props: PropsType) => props.borderRadius || null};
  background-color: ${(props: PropsType) => props.backgroundColor || 'transparent'};
  cursor: pointer;
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  position: relative;
  transition: ease 0.4s;
  max-width: ${(props: PropsType) => props.maxWidth || null};

  @media screen and (max-width: 768px) {
    font-size: 16px;
    height: 50px;
    border-radius: 15px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1400px) {
    height: 60px;
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    height: 70px;
    font-size: 16px;
  }

  @media screen and (min-width: 2000px) {
    height: 70px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;
    height: 80px;
  }
`;

export const SimpleButtonQr = styled.button`
  width: 100%;
  height: 60px;
  padding: ${(props: PropsType) => props.padding || '0'};
  border: ${(props: PropsType) => props.border || '1px solid white'};
  color: ${(props: PropsType) => props.color || 'white'};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  border-radius: 22px;
  background-color: ${(props: PropsType) => props.backgroundColor || 'transparent'};
  cursor: pointer;
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  position: relative;
  transition: ease 0.4s;

  > div {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
    position: absolute;
    bottom: -240px;
    left: 0;
    width: 230px;
    height: 230px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;

    > img {
      max-width: 100%;
    }
  }

  :hover {
    background-color: #333333;
    color: white;
    border: 1px solid #333333;
  }

  :hover img.svg-icon {
    fill: white;
    filter: invert(1);
  }

  :hover > div {
    transition: opacity 0.4s;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    height: 40px;
    padding: 10px 15px;
    border-radius: 12px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    height: 50px;
    border-radius: 15px;
  }

  @media screen and (min-width: 1400px) {
    height: 60px;
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    height: 60px;
    font-size: 16px;
  }

  @media screen and (min-width: 2000px) {
    height: 60px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;
    height: 80px;

    > img {
      height: 20px;
    }
  }
`;

export const PopupWrapper = styled.div`
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: ${(props: PropsType) => (props.showPopup || !props.closePopup ? 1 : 0)};
    transition: visibility 0s, opacity 0.3s linear;
    visibility: ${(props: PropsType) => (props.showPopup || !props.closePopup ? 'visible' : 'hidden')};
    padding: 60px;
    z-index: 99999;
    margin-left: -376px;
    margin-top: -180px;
    height: ${(props: PropsType) => props.height || 'auto'};
    width: ${(props: PropsType) => props.width || '758px'};
    border-radius: 22px;
    background-color: ${(props: PropsType) => props.backgroundColor || '#F57D20'};

    @media screen and (max-width: 768px) {
      border-radius: 10px;
      width: auto;
      left: 0;
      top: 0;
      padding: 30px;
      height: auto;
      margin-top: 190px;
      margin-left: 20px;
      margin-right: 20px;

      a {
        width: 100%;
        margin-right: 0;
      }

      .popup-buttons-wrapper {
        flex-direction: row;
      }
    }
  }
`;

export const Badge = styled.div`
  display: ${(props: PropsType) => props.display || 'flex'};
  font-style: normal;
  font-weight: ${(props: PropsType) => props.fontWeight || 500};
  font-size: 16px;
  line-height: 34px;
  justify-content: space-between;
  height: 34px;
  color: ${(props: PropsType) => props.color || '#ffffff'};
  background-color: ${(props: PropsType) => props.backgroundColor + '!important' || null};
  opacity: 1;
  margin-right: 10px;
  border-radius: 26px;
  padding: ${(props: PropsType) => props.padding || '0 10px'};
  margin-bottom: 10px;
  margin: ${(props: PropsType) => props.margin || null};

  @media screen and (max-width: 370px) {
    font-size: 10px !important;
    margin-right: 2px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 2px 6px;
    height: 23px;
    line-height: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    height: auto !important;
    line-height: 20px !important;
    font-size: 10px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1199px) {
    height: auto !important;
    line-height: 20px !important;
    font-size: 12px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;
    padding: 4px 12px;
    height: auto;
    > img {
      width: 30px;
    }
  }
`;

export const Bold = styled.span`
  font-weight: 800;
`;

export const Span = styled.span`
  font-size: ${(props: PropsType) => props.fontSize || null};
`;

export const BadgeOrange = styled(Badge)`
  background-color: #fd9f3a;
  @media screen and (max-width: 768px) {
    &.mobile-font-size-14 {
      font-size: 14px !important;
      padding: 15px 10px !important;

      & img {
        width: 15px;
      }
    }
  }
`;

export const BadgeCircleOrange = styled.div`
  background-color: #fd9f3a;
  border-radius: 100%;
  color: white;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  min-width: 44px;
  width: 44px;
`;

export const BadgeGray = styled(Badge)`
  background-color: #353539;
`;

export const BadgeLightGray = styled(Badge)`
  background-color: #dfdfdf;
  color: #1e1e23;
`;

export const BadgeBlack = styled(Badge)`
  background-color: #1e1e23;
  color: #ffffff;
`;

export const FlexWrapper = styled.div`
  flex-wrap: ${(props: PropsType) => props.flexWrap || 'initial'};
  background-color: ${(props: PropsType) => props.backgroundColor || 'transparent'};
  overflow: ${(props: PropsType) => props.overflow || 'inherit'};
  display: ${(props: PropsType) => props.display || 'flex'};
  align-items: ${(props: PropsType) => props.alignItems || 'center'};
  flex-direction: ${(props: PropsType) => props.flexDirection || 'row'};
  justify-content: ${(props: PropsType) => props.justifyContent || 'space-between'};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
  height: ${(props: PropsType) => props.height || 'auto'};
  padding: ${(props: PropsType) => props.padding || '0px'};
  width: ${(props: PropsType) => props.width || 'auto'};
  margin-bottom: ${(props: PropsType) => props.marginBottom || '0'};
  position: ${(props: PropsType) => props.position || 'static'};
  border-radius: ${(props: PropsType) => props.borderRadius || null};
  max-height: ${(props: PropsType) => props.maxHeight || null};
  margin: ${(props: PropsType) => props.margin || null};
  min-height: ${(props: PropsType) => props.minHeight || null};
  border-top-left-radius: ${(props: PropsType) => props.borderTopLeftRadius || '0'};
  border-bottom-left-radius: ${(props: PropsType) => props.borderBottomLeftRadius || '0'};
  border-top-right-radius: ${(props: PropsType) => props.borderTopRightRadius || '0'};
  border-bottom-right-radius: ${(props: PropsType) => props.borderBottomRightRadius || '0'};

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    &&.mobile-flex-reverse {
      flex-direction: column-reverse;
    }

    &&.mobile-stay-flex {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &&.mobile-justify-content-space-between {
      justify-content: space-between;
    }
  }
`;

export const FlexWrapperStart = styled(FlexWrapper)`
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    /* display: block; */
    flex-wrap: wrap;
    &&.mobile-stay-flex {
      flex-direction: row;
      display: flex;
    }
  }
`;

export const FlexWrapperNoAlign = styled(FlexWrapper)`
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    display: block;
    &&.mobile-stay-flex {
      flex-direction: row;
      display: flex;
    }

    &&.mobile-align-items-center {
      align-items: center;
    }

    &&.mobile-margin-20 {
      margin: 20px 0px;
    }
  }
`;

export const FlexWrapperCenter = styled(FlexWrapper)`
  justify-content: center;
`;

export const Divider = styled.hr<any>`
  margin: ${(props: PropsType) => props.margin || '30px 0'};
  opacity: 0.2;
  border: ${(props: PropsType) => props.borderHeight + ' solid ' + props.color || '1px solid #ffffff'};
`;

export const AboveTitle = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: ${(props: PropsType) => props.textAlign || 'center'};
  letter-spacing: 0.05em;
  text-transform: ${(props: PropsType) => props.textTransform || 'uppercase'};
  color: ${(props: PropsType) => props.color || '#1e1e23'};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 24px;
  }
`;

export const BlogTitle = styled.h1`
  font-style: normal;
  font-weight: ${(props: PropsType) => props.fontWeight || 400};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  margin-top: ${(props: PropsType) => props.marginTop || null};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  font-size: ${(props: PropsType) => props.fontSize || '72px'};
  line-height: 111.5%;
  text-align: ${(props: PropsType) => props.textAlign || 'center'};
  color: ${(props: PropsType) => props.color || '#3b3b3c'};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
`;

export const BigTitle = styled.h1`
  font-style: normal;
  font-weight: ${(props: PropsType) => (COUNTRY === 'mk' ? 600 : props.fontWeight) || 400};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  font-family: ${(props: PropsType) => props.fontFamily || null};
  margin-top: ${(props: PropsType) => props.marginTop || null};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  font-size: ${(props: PropsType) => props.fontSize || '72px'};
  line-height: 111.5%;
  text-align: ${(props: PropsType) => props.textAlign || 'center'};
  color: ${(props: PropsType) => props.color || '#1e1e23'};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};

  @media screen and (max-width: 370px) {
    font-size: 38px !important;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    font-size: 64px !important;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    font-size: 44px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    font-size: 54px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1400px) {
    font-size: 60px;
  }

  @media screen and (min-width: 1601px) {
    font-size: 72px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 72px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 92px;
  }

  @media screen and (min-width: 2400px) {
    font-size: 112px;
  }
`;

export const Title = styled.h2<PropsType>`
  font-style: normal;
  font-weight: ${(props: PropsType) => (COUNTRY === 'mk' ? 600 : props.fontWeight) || 800};
  font-size: ${(props: PropsType) => props.fontSize || '56px'};
  font-family: ${(props: PropsType) => props.fontFamily || null};
  line-height: ${(props: PropsType) => props.lineHeight || '130%'};
  text-align: ${(props: PropsType) => props.textAlign || 'center'};
  color: ${(props: PropsType) => props.color || '#1e1e23'};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  background-color: ${(props: PropsType) => props.backgroundColor || null};
  border-radius: ${(props: PropsType) => props.borderRadius || null};
  min-height: ${(props: PropsType) => props.minHeight || null};

  @media screen and (max-width: 370px) {
    font-size: 24px !important;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    font-size: 40px;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    font-size: 32px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 36px;
  }

  @media screen and (min-width: 1400px) {
    font-size: 44px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 44px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 54px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 64px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

export const SmallTitle = styled.h3`
  font-style: normal;
  font-weight: ${(props: PropsType) => props.fontWeight || 600};
  font-family: ${(props: PropsType) => props.fontFamily || null};

  font-size: ${(props: PropsType) => props.fontSize || '64px'};
  line-height: 130%;
  text-align: ${(props: PropsType) => props.textAlign || 'center'};
  color: ${(props: PropsType) => props.color || '#1e1e23'};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  margin-top: ${(props: PropsType) => props.marginTop || null};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  min-height: ${(props: PropsType) => props.minHeight || 'auto'};

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 22px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 32px;
  }
`;

export const Wrapper = styled.div`
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
  height: ${(props: PropsType) => props.height || 'auto'};
  width: ${(props: PropsType) => props.width || 'auto'};
  overflow: ${(props: PropsType) => props.overflow || 'inherit'};
  position: ${(props: PropsType) => props.position || 'relative'};
  background-color: ${(props: PropsType) => props.backgroundColor || 'transparent'};
  padding: ${(props: PropsType) => props.padding || '0px 0px'};
  text-align: ${(props: PropsType) => props.textAlign || 'left'};
  background-image: ${(props: PropsType) => props.backgroundImage || 'none'};
  border-radius: ${(props: PropsType) => props.borderRadius || '0px'};
  background-position: top;
  background-size: ${(props: PropsType) => props.backgroundSize || 'cover'};
  margin: ${(props: PropsType) => props.margin || '0px 0px'};
  margin-top: ${(props: PropsType) => props.marginTop || null};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  padding-top: ${(props: PropsType) => props.paddingTop || null};
  padding-right: ${(props: PropsType) => props.paddingRight || null};
  border-bottom: ${(props: PropsType) => props.borderBottom || 'none'};
  min-height: ${(props: PropsType) => props.minHeight || null};
  border: ${(props: PropsType) => props.border || null};
  display: ${(props: PropsType) => props.display || null};
  justify-content: ${(props: PropsType) => props.justifyContent || 'space-between'};
  @media screen and (max-width: 768px) {
    &&.mobile-padding-0 {
      padding: 0;
    }

    && h4 {
      font-size: 12px;
    }

    /* && h2 {
      font-size: 28px;
    } */
  }
`;

export const FlexCol = styled.div`
  display: ${(props: PropsType) => props.display || 'inline'};
  align-items: ${(props: PropsType) => props.alignItems || 'center'};
  flex-direction: ${(props: PropsType) => props.flexDirection || 'initial'};
  justify-content: ${(props: PropsType) => props.justifyContent || 'space-between'};
  width: ${(props: PropsType) => props.width || '50%'};
  height: ${(props: PropsType) => props.height || 'auto'};
  flex: 0 0 ${(props: PropsType) => props.width || '50%'};
  padding: ${(props: PropsType) => props.padding || null};
  position: ${(props: PropsType) => props.position || 'static'};
  max-height: ${(props: PropsType) => props.maxHeight || 'auto'};
  background-color: ${(props: PropsType) => props.backgroundColor || 'transparent'};
  margin-bottom: ${(props: PropsType) => props.marginBottom || '0px'};
  text-align: ${(props: PropsType) => props.textAlign || 'left'};
  border-radius: ${(props: PropsType) => props.borderRadius || '0px'};
  overflow: ${(props: PropsType) => props.overflow || null};
  border-right: ${(props: PropsType) => props.borderRight || null};
  border-bottom: ${(props: PropsType) => props.borderBottom || null};

  &&.flex-col-z-index {
    position: relative;
    z-index: 9999;
  }
`;

export const Img = styled.img`
  object-fit: ${(props: PropsType) => props.objectFit || 'fill'};
  width: ${(props: PropsType) => props.width || 'auto'};
  margin-right: ${(props: PropsType) => props.marginRight || 0};
  margin-bottom: ${(props: PropsType) => props.marginBottom || '0'};
  position: ${(props: PropsType) => props.position || 'static'};
  display: ${(props: PropsType) => props.display || 'block'};
  left: ${(props: PropsType) => props.left || null};
  margin: ${(props: PropsType) => props.margin || null};
`;

export const ImgWrapper = styled.div`
  max-height: ${(props: PropsType) => props.maxHeight || '100%'};
  width: 100%;
  height: ${(props: PropsType) => props.height || null};
  text-align: ${(props: PropsType) => props.textAlign || 'left'};
  position: ${(props: PropsType) => props.position || 'static'};
  overflow: ${(props: PropsType) => props.overflow || null};
  max-width: ${(props: PropsType) => props.maxWidth || null};
  border-radius: ${(props: PropsType) => props.borderRadius || null};
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: ${(props: PropsType) => props.fontWeight || 300};
  font-family: ${(props: PropsType) => props.fontFamily || null};
  margin: ${(props: PropsType) => props.margin || null};
  margin-top: ${(props: PropsType) => props.marginTop || null};
  margin-bottom: ${(props: PropsType) => props.marginBottom || null};
  margin-left: ${(props: PropsType) => props.marginLeft || null};
  margin-right: ${(props: PropsType) => props.marginRight || null};
  font-size: ${(props: PropsType) => props.fontSize || '14px'};
  color: ${(props: PropsType) => props.color || 'black'};
  opacity: ${(props: PropsType) => props.opacity || 1};
  max-width: ${(props: PropsType) => props.maxWidth || 'auto'};
  text-align: ${(props: PropsType) => props.textAlign || 'left'};
  line-height: ${(props: PropsType) => props.lineHeight || 'auto'};
  min-height: ${(props: PropsType) => props.minHeight || null};
  max-height: ${(props: PropsType) => props.maxHeight || null};

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    font-size: 14px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
